exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-creators-js": () => import("./../../../src/pages/creators.js" /* webpackChunkName: "component---src-pages-creators-js" */),
  "component---src-pages-data-deletion-js": () => import("./../../../src/pages/data-deletion.js" /* webpackChunkName: "component---src-pages-data-deletion-js" */),
  "component---src-pages-imprint-js": () => import("./../../../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-templates-streamer-js": () => import("./../../../src/templates/streamer.js" /* webpackChunkName: "component---src-templates-streamer-js" */)
}

