import React from 'react'
import styled from 'styled-components';
import { usePageContext }  from '../../hooks/usePageContext'
import { navigate } from 'gatsby'

const Wrapper = styled.div`
  display:flex;
  width:40% ;
`;

const LangOption = styled.div`
  color:white;
  cursor:pointer;
  font-size:14px;
  letter-spacing: 0.2em;
  padding:10px 0;
  text-transform:uppercase;
  display:flex;
  align-items:center;
  letter-spacing: 0.2em;
  font-family:${props => props.active === true ? 'TT Norms Pro Medium' : 'TT Norms Pro'};
  :first-child:after{
    content:"";
    border-left:2px solid white;
    margin:10px 10px;
    padding-top:30px;
  }
`

function switchLanguages(language, paths){
  navigate(paths[language])
}

function LangSwitcher() {
  const {paths, lang, languages} = usePageContext();
  
  return (
    <Wrapper>
      {languages.map((langOption, index) => (
        <LangOption 
          key={`langoption-${index}`} 
          active={langOption === lang}
          onClick={() => switchLanguages(langOption, paths)}
        >
          {langOption}
        </LangOption>        
      ))}
    </Wrapper>
  )
}

export default LangSwitcher