import React from 'react'
import { Helmet } from "react-helmet"
import FaviconIco from './favicon.ico'
import { useTranslation  } from 'react-i18next'

function Seo() {
  const { t } = useTranslation('')

  return (
    <Helmet>
      <title>{t('seo.title')}</title>
      <link rel="icon" type="image/png" href={FaviconIco
      } sizes="16x16" />
      <meta name="description" content={t('seo.description')} />
    </Helmet>
  )
}

export default Seo